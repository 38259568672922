import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { useRootContext } from '@/context/root-context';
import { Datepicker, locale } from '@mobiscroll/react';
import { calendarIcon } from './calendar-icon';
import { SearchInput } from './category-search-input';
import './mobiscroll-overwrite.css';

export const CategoryFilters = () => {
  const iconColor = `var(${APP_CSS_THEME_VARIABLES.icons})`;
  const { dateFromFilter, setDateFromFilter, dateToFilter, setDateToFilter } =
    useRootContext();
  const { locale: appLocale } = useRootContext();
  const onApplyClick = ({ value }) => {
    Promise.all([
      setDateFromFilter(value[0].toISOString()),
      setDateToFilter(value[1].toISOString()),
    ]);
  };

  return (
    <section className='bg-[var(--primary)] grid grid-cols-[minmax(0,1fr)_auto] justify-center items-center px-[16px] gap-[10px] h-[55px] laptop:h-[85px] laptop:gap-[40px] laptop:grid-cols-[minmax(auto,930px)_240px]'>
      <SearchInput />
      <Datepicker
        locale={locale[appLocale]}
        theme='ios'
        themeVariant='light'
        controls={['calendar']}
        touchUi
        select='range'
        dateFormat='DD.MM.YYYY'
        value={[dateFromFilter, dateToFilter]}
        onChange={onApplyClick}
        display='anchored'
        inputProps={{
          className: 'top-filter__input border-none',
        }}
        startIconSvg={calendarIcon(iconColor)}
      />
    </section>
  );
};
