import { AppLoader } from '@/components/app-loader';
import { useIntl } from 'react-intl';
import { SubscriptionGroup } from './app-plan.types';
import { ComingSoon } from './coming-soon';
import { PlanInfo } from './plan-info';
import c from 'classnames';
import { usePlanLogic } from './use-plan-logic';
import { addClickHandle } from '@/services/helper-service';

const maxDocsForFree = 7;

export const Plan = () => {
  const {
    onTabTileClick,
    onTabClick,
    activePlanTab,
    plansList,
    loader,
    planBenefits,
  } = usePlanLogic();
  const { formatMessage: t } = useIntl();
  const isBusiness = activePlanTab === SubscriptionGroup.business;
  if (!isBusiness && !plansList) {
    return <AppLoader />;
  }

  return (
    <div className='flex flex-col h-full laptop:w-[45%] laptop:h-[670px]'>
      <div className='font-roboto p-[30px_20px_0] flex flex-col max-w-[428px] mx-auto tablet:max-w-[667px] laptop:max-w-unset laptop:p-0 laptop:m-0'>
        <div className='font-bold text-[24px] leading-[28px] text-center text-[var(--textMain)]'>
          {t({ id: 'menu.profile.plan.title' })}
        </div>

        <div className='w-[277px] m-[10px_auto_0] font-normal text-[18px] leading-[21px] text-center text-[var(--textMain)] opacity-[0.9] laptop:w-full'>
          {t({ id: 'menu.profile.plan.description' })}
        </div>

        <ul className='m-[25px_auto_0] pl-0 flex max-w-[343px] w-full list-none laptop:max-w-unset'>
          <ActivePlanTab
            label={t({ id: 'menu.profile.plan.personal' })}
            onClick={onTabClick}
            active={activePlanTab === SubscriptionGroup.plus}
            subscriptionGroup={SubscriptionGroup.plus}
          />
          <ActivePlanTab
            label={t({ id: 'menu.profile.plan.family' })}
            onClick={onTabClick}
            active={activePlanTab === SubscriptionGroup.premium}
            subscriptionGroup={SubscriptionGroup.premium}
          />
          <ActivePlanTab
            label={t({ id: 'menu.profile.plan.business' })}
            onClick={onTabClick}
            active={activePlanTab === SubscriptionGroup.business}
            subscriptionGroup={SubscriptionGroup.business}
          />
        </ul>
        {activePlanTab !== SubscriptionGroup.business ? (
          <PlanInfo
            {...{
              planBenefits,
              onTabTileClick,
              planInfo: plansList,
              subscriptionGroup: activePlanTab,
            }}
          />
        ) : (
          <ComingSoon
            onClick={() => {
              alert(t({ id: 'menu.profile.plan.coming.soon.alert' }));
            }}
          />
        )}

        {activePlanTab === SubscriptionGroup.plus && (
          <div className='mt-[45px]  font-bold text-[22px] leading-[26px] text-center text-[var(--emphasized)] laptop:mt-[35px]'>
            {t({ id: 'menu.profile.plan.subtitle.1' })}
            {maxDocsForFree}
            {t({ id: 'menu.profile.plan.subtitle.2' })}
          </div>
        )}
      </div>
      {activePlanTab !== SubscriptionGroup.business && (
        <div className='mt-auto p-[8px_20px_30px] font-normal text-[9px] leading-[11px] text-[var(--textMain40)] bg-[var(--mainBackground)] laptop:p-[0_0_20px] laptop:bg-transparent'>
          {t({ id: 'menu.profile.plan.bottom-info' })}
        </div>
      )}
      {loader && <AppLoader />}
    </div>
  );
};

type ActivePlanTabProps = {
  onClick(subscriptionGroup: SubscriptionGroup): void;
  active: boolean;
  subscriptionGroup: SubscriptionGroup;
  label: any;
};

export const ActivePlanTab = ({
  onClick,
  subscriptionGroup,
  active,
  label,
}: ActivePlanTabProps) => {
  const onTabClick = () => {
    onClick(subscriptionGroup);
  };

  return (
    <li
      {...addClickHandle(onTabClick)}
      className={c(
        'cursor-pointer p-[6px_0] flex items-center justify-center w-[33.33%] font-medium text-[14px] leading-[22px] tracking-[-0.408px]  not:last:border-r-[1px] not:last:border-[var(--emphasized30)]',
        {
          'bg-[var(--emphasized)] text-[#f6f6f6] opacity-100': active,
        },
        { 'bg-[var(--emphasized10)] text-[var(--emphasized)]': !active },
      )}
    >
      {label}
    </li>
  );
};
