import { categoryListViewAtom } from '@/store';
import c from 'classnames';
import { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { AppIcon } from '@/components';
import { CategoryId, CategoryView } from '@/core.types';
import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';

const TILE_FOLDER_ICON_SIZE = {
  width: 130,
  height: 90,
};

const LIST_FOLDER_ICON_SIZE = {
  width: 35,
  height: 28,
};

export const DndPreviewFolderItem = ({ id }: { id: CategoryId }) => {
  const { getCategoryById } = useContext(CategoryTreeContext);
  const localCategory = getCategoryById(id);
  const listView = useRecoilValue(categoryListViewAtom);
  const isTableView = listView === CategoryView.list;

  if (!localCategory) {
    return null;
  }

  return (
    <div
      className={c('w-[130px] flex flex-col mb-[5px]', {
        'flex-row items-center': isTableView,
      })}
    >
      <AppIcon
        name={
          localCategory.children.length || localCategory.receipts.length
            ? 'green-folder-non-empty'
            : 'green-folder-empty'
        }
        size={isTableView ? LIST_FOLDER_ICON_SIZE : TILE_FOLDER_ICON_SIZE}
      />
      <div
        className={c(
          'text-[rgba(26,28,41,0.6)] font-medium text-[14px] leading-[16px] pb-[2px]',
          {
            'pt-0 ml-[5px]': isTableView,
          },
        )}
      >
        {localCategory.name}
      </div>
    </div>
  );
};
