import { AppType } from '@/core.types';
import { hasAppAccess } from '@/services/app-service';
import { receiptTypeAtom } from '@/store/category-store';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';

export const CategoryListNoChild = () => {
  const receiptType = useRecoilValue(receiptTypeAtom);
  const { formatMessage: t } = useIntl();

  return (
    <p className='text-center text-[#233073] w-full pt-[15px]'>
      {hasAppAccess(AppType.idocument) ? (
        t({ id: 'categoryList.no-documents' })
      ) : (
        <>
          {t({ id: 'categoryList.dont.1' })} {receiptType}
          {t({ id: 'categoryList.dont.2' })}
        </>
      )}
    </p>
  );
};
