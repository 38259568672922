import {
  ALL_PROFILES,
  NAVIGATOR_LANG_MAP,
  SETTINGS_LANGUAGE_MAP,
  STORAGE_KEYS,
} from '@/app.constants';
import { CreatorType, Maybe, SortField, SortType } from '@/core.types';
import { getMonthPeriod } from '@/services/helper-service';
import { resolveRecognitionByLanguage } from '@/store/store.effects';
import { isPlatform } from '@ionic/react';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocalStorage } from 'react-use';

interface RootContextProps {
  locale: string;
  setLocale: (locale: string) => void;
  filterMadeBy: CreatorType;
  setFilterMadeBy: Dispatch<SetStateAction<CreatorType>>;
  searchFilter: string;
  setSearchFilter: Dispatch<SetStateAction<string>>;
  sortFilter: { sortField: SortField; sortType: SortType };
  setSortFilter: Dispatch<
    SetStateAction<{ sortField: SortField; sortType: SortType }>
  >;
  dateFromFilter?: Date;
  setDateFromFilter: Dispatch<SetStateAction<string | undefined>>;
  dateToFilter?: Date;
  setDateToFilter: Dispatch<SetStateAction<string | undefined>>;
  // For text recognition between 3 steps
  recognizedImageTexts: Maybe<string[]>;
  setRecognizedImageTexts: Dispatch<SetStateAction<Maybe<string[]>>>;
  rootID?: Maybe<string>;
  setRootID: Dispatch<SetStateAction<Maybe<string> | undefined>>;
  activeSliceId?: Maybe<string>;
  setActiveSliceId: Dispatch<SetStateAction<Maybe<string> | undefined>>;
  isRoot: boolean;
}

const RootContext = createContext<RootContextProps | undefined>(undefined);

const DEFAULT_LOCALE = 'en';

interface RootProviderProps {
  children: ReactNode;
}

export const RootProvider = ({ children }: RootProviderProps) => {
  const [locale, setLocale] = useLocalStorage<string>(
    STORAGE_KEYS.locale,
    DEFAULT_LOCALE,
  );
  const [filterMadeBy, setFilterMadeBy] = useState(ALL_PROFILES);
  const [searchFilter, setSearchFilter] = useState('');
  const [sortFilter, setSortFilter] = useState({
    sortField: SortField.receiptDate,
    sortType: SortType.desc,
  });
  const { dateFrom, dateTo } = getMonthPeriod();
  const [dateFromFilter, setDateFromFilter] = useLocalStorage(
    STORAGE_KEYS.dateFromFilter,
    dateFrom.toISOString(),
  );
  const [dateToFilter, setDateToFilter] = useLocalStorage(
    STORAGE_KEYS.dateToFilter,
    dateTo.toISOString(),
  );
  const [rootID, setRootID] = useLocalStorage<Maybe<string>>(
    STORAGE_KEYS.rootID,
    null,
  );
  const [activeSliceId, setActiveSliceId] = useLocalStorage<Maybe<string>>(
    STORAGE_KEYS.activeSliceId,
    null,
  );

  const isRoot = rootID === activeSliceId;

  const [recognizedImageTexts, setRecognizedImageTexts] = useState<
    Maybe<string[]>
  >([]);

  useEffect(() => {
    if (!locale) {
      setLocale(NAVIGATOR_LANG_MAP[navigator.language] || DEFAULT_LOCALE);
    }
  }, []);

  const changeLocale = (newLocale: string) => {
    if (!locale || !SETTINGS_LANGUAGE_MAP.includes(locale)) {
      setLocale(DEFAULT_LOCALE);
    } else {
      setLocale(newLocale);
    }
  };

  useEffect(() => {
    if (isPlatform('capacitor') && locale) {
      resolveRecognitionByLanguage(locale);
    }
  }, [locale]);

  return (
    <RootContext.Provider
      value={{
        rootID,
        setRootID,
        activeSliceId,
        setActiveSliceId,
        isRoot,
        locale: locale ?? DEFAULT_LOCALE,
        setLocale: changeLocale,
        filterMadeBy,
        setFilterMadeBy,
        searchFilter,
        setSearchFilter,
        sortFilter,
        setSortFilter,
        dateFromFilter: new Date(dateFromFilter ?? dateFrom),
        setDateFromFilter,
        dateToFilter: new Date(dateToFilter ?? dateTo),
        setDateToFilter,
        recognizedImageTexts,
        setRecognizedImageTexts,
      }}
    >
      {children}
    </RootContext.Provider>
  );
};

export const useRootContext = () => {
  const context = useContext(RootContext);
  if (!context) {
    throw new Error('useRootContext must be used within a RootProvider');
  }
  return context;
};
