import { PAGE_ROUTES } from '@/app.constants';
import { AppButton } from '@/components/app-button';
import { ReceiptType } from '@/core.types';
import { receiptTypeAtom } from '@/store';
import c from 'classnames';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';

type CategoryTabProps = {
  receiptType: ReceiptType;
  label: string;
};

export type AppTabItem = {
  label: string;
  onClick(): void;
};

export const CategoryTabs = () => {
  const { formatMessage: t } = useIntl();
  const receiptType = useRecoilValue(receiptTypeAtom);

  return (
    <div className='relative w-full flex border-b border-[rgba(37,47,111,0.1)]'>
      <CategoryTab
        label={t({ id: 'receipts' })}
        receiptType={ReceiptType.receipt}
      />
      <CategoryTab
        label={t({ id: 'invoices' })}
        receiptType={ReceiptType.invoice}
      />
      <div
        className={c(
          'absolute bottom-0 transition-[0.3s] bg-[var(--emphasized50)] h-[2px] w-[50%] tablet:h-[4px]',
          {
            'left-0': receiptType === ReceiptType.receipt,
            'left-1/2': receiptType === ReceiptType.invoice,
          },
        )}
      />
    </div>
  );
};

export const CategoryTab = ({ receiptType, label }: CategoryTabProps) => {
  const history = useHistory();
  const onTabClick = useRecoilCallback(({ set }) => () => {
    set(receiptTypeAtom, receiptType);
    history.push(PAGE_ROUTES.category);
  });

  return (
    <AppButton
      className='cursor-pointer text-center w-1/2 font-medium text-[14px] leading-[22px] text-[var(--emphasized)] capitalize pt-[12px] pb-[7px] tablet:text-[16px] tablet:pt-[20px] tablet:pb-[10px]'
      onPress={onTabClick}
    >
      {label}
    </AppButton>
  );
};
