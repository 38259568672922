import { useSyncCategoryTree } from '@/category-tree-hooks/use-sync-category-tree';
import { Maybe } from '@/core.types';
import { useSwitchUser } from '@/gql/api-user';
import { currentUserAtom } from '@/store/user-store';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import type { Account, User } from './account.types';
import { resolveFilePath } from '@/services/helper-service';

export const useQuerySharedAccounts = () => {
  const [loading, setLoading] = useState(false);
  const [accountData, setAccountData] = useState<any>(null);
  const [activeId, setActiveId] = useState<Maybe<number>>(null);
  const userPayload = useRecoilValue(currentUserAtom);

  const switchUser = useSwitchUser();
  const startSync = useSyncCategoryTree();

  useEffect(() => {
    const getCommonAccountInfo = ({
      ownerFullName,
      ownerCurrency,
      id,
      ownerPictureUrl,
    }: Account) => ({
      ownerFullName,
      ownerCurrency,
      id,
      ownerPictureUrl: resolveFilePath(ownerPictureUrl),
    });

    const updateState = ({ account, currentAccount }: User) => {
      setAccountData({
        personalAccount: getCommonAccountInfo(account),
        availableAccount: [
          ...account.availableAccountShares.map(
            ({ accountFrom, canViewGroup, canEditGroup, canDeleteGroup }) => ({
              ...getCommonAccountInfo(accountFrom),
              canViewGroup,
              canEditGroup,
              canDeleteGroup,
            }),
          ),
        ],
      });

      setActiveId(currentAccount.id);
    };

    if (userPayload?.user) {
      // @ts-ignore
      updateState(userPayload?.user);
    }
  }, [userPayload]);

  const switchAccount = async (account_id: number) => {
    try {
      setLoading(true);
      // Additional check for online status
      await switchUser(account_id);
      setActiveId(account_id);
      startSync();
      setLoading(false);
    } catch (error) {
      if (error instanceof Error) {
        console.warn(error.message);
      }
    }
  };

  return {
    data: accountData,
    activeId,
    loading: false,
    switchAccount,
    changeAccountInprogress: loading,
  };
};
