import { AppIcon } from '@/components';
import { useRootContext } from '@/context/root-context';
import { LocalCategory } from '@/core.types';
import { useCreateCategory, useUpdateCategoryList } from '@/hooks';
import { receiptTypeAtom } from '@/store';
import { useEffect, useRef, useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import { MOBILE_FOLDER_ICON_SIZE } from './create-receipt-modal-tree-folder';

type CreateReceiptModalTreeFolderInputProps = {
  closeInput(): void;
  category?: LocalCategory;
};

export const CreateReceiptModalTreeFolderInput = ({
  category,
  closeInput,
}: CreateReceiptModalTreeFolderInputProps) => {
  const createCategory = useCreateCategory();
  const updateCategoryList = useUpdateCategoryList();
  const [folderName, setFolderName] = useState(category?.name);
  const inputRef = useRef<null | HTMLInputElement>(null);
  const { activeSliceId } = useRootContext();

  const folderIconSize = MOBILE_FOLDER_ICON_SIZE;

  const onInputChange = ({ target: { value } }) => {
    setFolderName(value);
  };

  const onBlurInput = useRecoilCallback(({ snapshot }) => () => {
    const receiptType = snapshot.getLoadable(receiptTypeAtom).contents;

    if (folderName && activeSliceId) {
      category
        ? updateCategoryList([{ ...category, name: folderName }])
        : createCategory({
            id: uuidv4(),
            name: folderName,
            type: receiptType,
            parent_id: activeSliceId,
          });
    }
    closeInput();
  });

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className='flex items-center w-full'>
      <AppIcon
        name='green-folder-non-empty'
        size={folderIconSize}
        id={category ? category.id : 'new-folder'}
      />
      <input
        type='text'
        ref={inputRef}
        className='border-none border-b border-[rgba(26,28,41,0.2)] bg-transparent h-[29px] text-[16px] ml-[20px] text-textMain flex-grow'
        onChange={onInputChange}
        onBlur={onBlurInput}
      />
    </div>
  );
};
