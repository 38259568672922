import { PAGE_ROUTES } from '@/app.constants';
import { applyColorTheme } from '@/color-theme.constants';
import { CategoryId } from '@/core.types';
import { useGetUser } from '@/gql/api-user';
import { useDetectOnlineApplication } from '@/hooks/use-detect-online-application';
import { SwipeBack } from 'capacitor-plugin-ios-swipe-back';
import { useCallback, useEffect, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import {
  fileDownloadQueueTable,
  requestTable,
} from '../services/indexed-db-service';
import {
  appThemeTypeAtom,
  imageQueryQueueItemsCountAtom,
  queryQueueItemsCountAtom,
  tempIdMapAtom,
} from '../store';
import { useOpenWith } from './use-open-with';
import { useRootContext } from '@/context/root-context';

export const useRootInit = (isAuthorized: boolean) => {
  const history = useHistory();
  const setQueryQueueItemsCount = useSetRecoilState(queryQueueItemsCountAtom);
  const setImageQueryQueueItemsCount = useSetRecoilState(
    imageQueryQueueItemsCountAtom,
  );
  const { userLoading, fetchUser } = useGetUser();
  const { pathname } = useLocation();
  const { setActiveSliceId, rootID, activeSliceId } = useRootContext();

  useEffect(() => {
    if (!window.cordova) {
      document.body.style.minHeight = '-webkit-fill-available';
    }

    // TODO update counters
    requestTable
      .count()
      .then((count: number) => setQueryQueueItemsCount(count));

    fileDownloadQueueTable
      .count()
      .then((count: number) => setImageQueryQueueItemsCount(count));
  }, [setImageQueryQueueItemsCount, setQueryQueueItemsCount]);

  useEffect(() => {
    if (isAuthorized) {
      fetchUser();
    }
  }, [fetchUser, isAuthorized]);

  const onChangePathName = useRecoilCallback(
    ({ snapshot }) =>
      async (newCategoryId?: CategoryId) => {
        if (!activeSliceId) return;

        const tempIdMap = snapshot.getLoadable(tempIdMapAtom).contents;
        const finalNewNewID = newCategoryId || rootID;
        if (finalNewNewID && finalNewNewID !== activeSliceId) {
          const finalId = tempIdMap[finalNewNewID] || finalNewNewID;
          setActiveSliceId(finalId);
        }
      },
  );

  const handleHistoryPathName = useCallback(
    ({ pathname }: { pathname: string }) => {
      if (
        pathname === PAGE_ROUTES.category ||
        PAGE_ROUTES.settings === pathname
      ) {
        SwipeBack.disable({ error: 0 });
      }
      if (
        !pathname.includes(PAGE_ROUTES.previewReceipt) &&
        !pathname.includes(PAGE_ROUTES.updateReceipt)
      ) {
        window.preloadImage = null;
      }
      if (
        !pathname.includes(PAGE_ROUTES.category) ||
        pathname.includes(PAGE_ROUTES.createReceipt) ||
        pathname.includes(PAGE_ROUTES.updateReceipt) ||
        pathname.includes(PAGE_ROUTES.previewReceipt) ||
        pathname.includes(PAGE_ROUTES.cropStep)
      ) {
        return;
      }

      const pathList = pathname
        .split('/')
        .filter((item) => !['/', 'category', ''].includes(item));

      const categoryIdFromURL = pathList[pathList.length - 1];
      const newId = Number.isNaN(Number(categoryIdFromURL))
        ? categoryIdFromURL
        : Number(categoryIdFromURL);
      onChangePathName(newId);
    },
    [onChangePathName],
  );

  const setThemeOnInit = useRecoilCallback(({ snapshot }) => () => {
    const appThemeType = snapshot.getLoadable(appThemeTypeAtom).contents;
    applyColorTheme(appThemeType);
  });

  useEffect(() => {
    handleHistoryPathName({ pathname });
    return history.listen(handleHistoryPathName);

    // !Note - this is really important, receiptToEdit is problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOpenWith();
  useDetectOnlineApplication();

  useLayoutEffect(() => {
    setThemeOnInit();
  }, [setThemeOnInit]);

  return {
    userLoading,
  };
};
