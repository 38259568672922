import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import { useCallback, useContext } from 'react';
import { useRecoilCallback } from 'recoil';
import { localReceiptFilesTable } from '@/services/indexed-db-service';
import { extractAmountAndName } from '@/services/receipt-text-service';
import { getSnapshotValue } from '@/store';
import {
  currentAccountIdSelector,
  currentUserNameSelector,
  userIdSelector,
} from '@/store/user-store';
import { v4 as uuidv4 } from 'uuid';
import {
  CategoryId,
  LocalFileReceipt,
  LocalReceipt,
  ReceiptId,
  REQUEST_TYPE,
  ScannedReceipt,
} from '../core.types';
import { receiptTypeAtom } from '../store';
import { addRequestItem } from './use-query-queue';

const createLocalFile = (localFile: LocalFileReceipt) => {
  localReceiptFilesTable.put(localFile);
};

export const useSetLocalReceiptImagesToReceiptsStore = () => {
  const { getReceiptById, updateReceiptList } = useContext(CategoryTreeContext);

  return useCallback(
    (localFile: LocalFileReceipt) => {
      const receiptToEdit = getReceiptById(localFile.id);

      if (receiptToEdit) {
        const newReceiptToSave = {
          ...receiptToEdit,
          id: localFile.id,
          imagePath: localFile.filePath,
          thumbPath: localFile.thumbPath,
        };

        updateReceiptList([newReceiptToSave]);
        localReceiptFilesTable.put(localFile);
      }
    },
    [getReceiptById, updateReceiptList],
  );
};

export const useCreateReceiptList = () => {
  const { activeSliceSelector, createNewReceiptList } =
    useContext(CategoryTreeContext);

  const createLocalReceipt = useRecoilCallback(
    ({ snapshot }) =>
      ({ text, imagePath, thumbPath }: ScannedReceipt): LocalReceipt => {
        const activeSlice = activeSliceSelector();
        const receiptType = getSnapshotValue(snapshot, receiptTypeAtom);
        const currentUserName = getSnapshotValue(
          snapshot,
          currentUserNameSelector,
        );
        const userId = getSnapshotValue(snapshot, userIdSelector);
        const id = uuidv4();
        const amountAndName = extractAmountAndName(text);

        const currentAccountId = snapshot.getLoadable(
          currentAccountIdSelector,
        ).contents;

        return {
          id,
          imagePath,
          thumbPath,
          name: amountAndName.name,
          parentId: activeSlice.id,
          amount: amountAndName.amount,
          type: receiptType,
          receiptDate: Math.round(Date.now() / 1000),
          createdBy: currentUserName,
          deleted: false,
          createdById: userId as number,
          updatedAt: null,
          remindedAt: null,
          accountId: currentAccountId,
        };
      },
    [],
  );

  return useCallback(
    async (scannedReceiptList: ScannedReceipt[]) => {
      const receipts = scannedReceiptList.map(createLocalReceipt);

      for (const receipt of receipts) {
        const localFile = {
          id: receipt.id,
          thumbPath: receipt.thumbPath,
          filePath: receipt.imagePath,
        };
        const newReceiptPayload = {
          ...receipt,
          category_id: receipt.parentId,
        };
        await createNewReceiptList([receipt]);
        createLocalFile(localFile);
        await addRequestItem({
          payload: newReceiptPayload,
          rollbackPayload: {
            parentId: receipt.parentId,
            ids: [receipt.id],
          },
          requestType: REQUEST_TYPE.createReceipt,
        });
      }
    },
    [createLocalReceipt, createNewReceiptList],
  );
};

export const useDeleteReceiptList = () => {
  const { deleteReceiptList, getReceiptById } = useContext(CategoryTreeContext);

  return useRecoilCallback(
    ({ snapshot }) =>
      async ({ ids, parentId }: { ids: ReceiptId[]; parentId: CategoryId }) => {
        const rollbackPayload: LocalReceipt[] = [];
        for (const id of ids) {
          const rollbackReceipt = getReceiptById(id);

          if (rollbackReceipt) {
            rollbackPayload.push(rollbackReceipt);
          }
        }

        deleteReceiptList({ parentId, ids });
        await addRequestItem({
          payload: ids,
          rollbackPayload,
          requestType: REQUEST_TYPE.deleteReceipt,
        });
      },
    [deleteReceiptList],
  );
};

export const useUpdateReceiptList = () => {
  const { updateReceiptList, getReceiptById } = useContext(CategoryTreeContext);

  return useRecoilCallback(
    () => async (receipts: LocalReceipt[]) => {
      updateReceiptList(receipts);
      for (const receipt of receipts) {
        const rollbackPayload = getReceiptById(receipt.id);

        await addRequestItem({
          payload: {
            ...receipt,
            category_id: receipt.parentId,
          },
          rollbackPayload: rollbackPayload as LocalReceipt,
          requestType: REQUEST_TYPE.updateReceipt,
        });
      }
    },
    [],
  );
};

export const useCreateReceiptFileFromMobileApp = () => {
  const { createNewReceiptList } = useContext(CategoryTreeContext);

  return useCallback(
    async (receipt: LocalReceipt) => {
      const newReceiptPayload = {
        ...receipt,
        category_id: receipt.parentId,
      };
      createNewReceiptList([receipt]);
      createLocalFile({
        id: receipt.id,
        thumbPath: receipt.thumbPath,
        filePath: receipt.imagePath,
      });

      await addRequestItem({
        payload: newReceiptPayload,
        rollbackPayload: {
          parentId: receipt.parentId,
          ids: [receipt.id],
        },
        requestType: REQUEST_TYPE.createReceipt,
      });
    },
    [createNewReceiptList],
  );
};
