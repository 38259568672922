import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import { useCallback, useContext } from 'react';
import { useRecoilCallback } from 'recoil';
import { getSnapshotValue } from '@/store';
import {
  currentAccountIdSelector,
  currentUserNameSelector,
  userIdSelector,
} from '@/store/user-store';
import {
  CategoryId,
  CreateCategoryPayload,
  LocalCategory,
  REQUEST_TYPE,
} from '../core.types';
import { addRequestItem } from './use-query-queue';

export const useCreateCategory = () => {
  const { createNewCategoryList } = useContext(CategoryTreeContext);

  const createLocalCategory = useRecoilCallback(
    ({ snapshot }) =>
      ({ name, parent_id, id, type }: CreateCategoryPayload) => {
        const currentUserName = getSnapshotValue(
          snapshot,
          currentUserNameSelector,
        );
        const userId = getSnapshotValue(snapshot, userIdSelector);
        const currentAccountId = getSnapshotValue(
          snapshot,
          currentAccountIdSelector,
        );

        return {
          id: id,
          name,
          type,
          deleted: false as boolean,
          parentId: parent_id,
          children: [],
          receipts: [],
          createdBy: currentUserName,
          createdById: userId as number,
          updatedAt: null,
          accountId: currentAccountId as number,
        } as LocalCategory;
      },
  );

  return useCallback(
    async (payload: CreateCategoryPayload) => {
      const localCategory = createLocalCategory(payload);
      createNewCategoryList([localCategory]);
      addRequestItem({
        payload: {
          id: localCategory.id,
          name: localCategory.name,
          parent_id: localCategory.parentId,
          type: localCategory.type,
        },
        rollbackPayload: localCategory.id,
        requestType: REQUEST_TYPE.createCategory,
      });
    },
    [createLocalCategory, createNewCategoryList],
  );
};

export const useUpdateCategoryList = () => {
  const { updateCategoryList, getCategoryById } =
    useContext(CategoryTreeContext);

  return useCallback(
    async (categoryList: LocalCategory[]) => {
      updateCategoryList(categoryList);
      for (const category of categoryList) {
        const payload = {
          id: category.id,
          name: category.name,
          parent_id: category.parentId,
        };

        const rollbackPayload = getCategoryById(category.id);

        await addRequestItem({
          payload: payload,
          rollbackPayload: rollbackPayload as LocalCategory,
          requestType: REQUEST_TYPE.updateCategory,
        });
      }
    },
    [getCategoryById, updateCategoryList],
  );
};

export const useDeleteCategoryList = () => {
  const { deleteCategoryList, getCategoryById } =
    useContext(CategoryTreeContext);

  return useCallback(
    async ({ ids, parentId }: { ids: CategoryId[]; parentId: CategoryId }) => {
      const categoryListRollback: LocalCategory[] = [];
      for (const id of ids) {
        const categoryToRollback = getCategoryById(id);
        if (categoryToRollback) {
          categoryListRollback.push(categoryToRollback);
        }
      }

      deleteCategoryList({ ids, parentId });
      await addRequestItem({
        payload: ids,
        rollbackPayload: categoryListRollback,
        requestType: REQUEST_TYPE.deleteCategory,
      });
    },
    [deleteCategoryList, getCategoryById],
  );
};
