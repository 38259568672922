import { PAGE_ROUTES } from '@/app.constants';
import { useGetReceiptName } from '@/hooks/use-get-receipt-name';
import { hasAppAccess } from '@/services/app-service';
import {
  detectLocalImagePath,
  unixTimeToString,
  unlockBackSwipe,
} from '@/services/helper-service';
import { categoryListViewAtom, receiptToEditAtom } from '@/store';
import {
  editModeEnabledATOM,
  selectedReceiptIDsAtom,
} from '@/store/category-store';
import { useSelectedIDsAtomSetters } from '@/store/category-store/category-store.callbacks';
import { currentUserCurrencySelector } from '@/store/user-store';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AppType, LocalReceipt } from '../core.types';

export const useReceiptItemLogic = (item: LocalReceipt) => {
  const { formatMessage: t } = useIntl();
  const { name } = item;
  const editModeEnabled = useRecoilValue(editModeEnabledATOM);
  const selectedReceiptIdsList = useRecoilValue(selectedReceiptIDsAtom);
  const [addSelectedReceiptId, removeSelectedReceiptId] =
    useSelectedIDsAtomSetters(selectedReceiptIDsAtom);
  const categoryListView = useRecoilValue(categoryListViewAtom);
  const setReceiptToEdit = useSetRecoilState(receiptToEditAtom);
  const currency = useRecoilValue(currentUserCurrencySelector);
  const history = useHistory();
  const isItemActive = !!selectedReceiptIdsList.find((id) => id === item.id);
  const itemName = useGetReceiptName({ name, type: item.type });

  const onItemClick = async () => {
    if (!detectLocalImagePath(item.imagePath) && window.cordova) {
      toast.warn(t({ id: 'notifications.tryOpenReceiptLater' }), {
        autoClose: 3000,
        position: 'bottom-center',
      });
      return;
    }

    if (!editModeEnabled) {
      await unlockBackSwipe();
      await setReceiptToEdit(item);

      history.push(`${PAGE_ROUTES.category}${PAGE_ROUTES.previewReceipt}`);
      return;
    }

    return isItemActive
      ? removeSelectedReceiptId(item.id)
      : addSelectedReceiptId(item.id);
  };

  const onItemDescriptionCLick = async () => {
    if (!detectLocalImagePath(item.imagePath) && window.cordova) {
      toast.warn('Try to open receipt later', {
        autoClose: 3000,
        position: 'bottom-center',
      });
      return;
    }
    await unlockBackSwipe();
    await setReceiptToEdit(item);
    history.push(`${PAGE_ROUTES.category}${PAGE_ROUTES.updateReceipt}`);
  };

  const date = unixTimeToString(item.receiptDate);

  return {
    isReceiptItemEmpty:
      (hasAppAccess(AppType.ireceipt) && item?.amount === 0) || !item.name,
    onItemDescriptionCLick,
    isItemActive,
    onItemClick,
    date,
    itemName,
    categoryListView,
    currency,
  };
};
