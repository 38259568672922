import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppBackButton } from '@/components/app-back-button';

export const CategoryHeaderBack = () => {
  const { activeSliceSelector } = useContext(CategoryTreeContext);
  const activeSlice = activeSliceSelector();
  const { goBack } = useHistory();

  const onBackClick = () => {
    if (activeSlice) {
      goBack();
    }
  };
  return (
    <AppBackButton
      onBackClick={onBackClick}
      label={activeSlice?.name}
      labelClassName='text-ellipsis whitespace-nowrap overflow-hidden'
      className='p-[10px] w-full pr-0 hover:drop-shadow-[0_0_2px_var(--emphasized)]'
    />
  );
};
