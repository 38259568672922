import { CategoryTreeProvider } from '@/category-tree-provider/category-tree-provider';
import { AuthRoute } from '@/components/auth-route';
import {
  currentUserSharedAccountSelector,
  isAuthorizedAtom,
  receiptToEditAtom,
  receiptTypeAtom,
  selectedCategoryIDsAtom,
  selectedReceiptIDsAtom,
  tempIdMapAtom,
  userIdSelector,
} from '@/store';
import { memo, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import useDocumentTitle from 'use-document-title';
import { Category } from '../category';
import { ForgotPasswordPage, LoginPage, OtpLogin } from '../scenes';
import { Settings } from '../scenes/Settings';
import { RootWrapper } from './root-wrapper';
// import { useCheckLanguage } from './use-check-language';
import { PAGE_ROUTES } from '@/app.constants';
import { useRootContext } from '@/context/root-context';
import { setLogoutFunction } from '@/errorLink';
import { useLogout } from '@/hooks/use-logout';
import { useSetFavicon } from '@/hooks/use-set-favicon';
import { AuthPage } from '@/scenes/auth-page';
import { MailConfirmPage } from '@/scenes/mail-confirm-page';
import { ResetPassword } from '@/scenes/reset-password-page';
import { SignUp } from '@/scenes/sign-up-page';
import { getAppName } from '@/services/app-service';
import { useRootInit } from './use-root-init';

export const Root = memo(() => {
  // useCheckLanguage();
  const isAuthorized = useRecoilValue(isAuthorizedAtom);
  useRootInit(isAuthorized);
  const logout = useLogout();

  useDocumentTitle(getAppName());
  useSetFavicon();

  // !TODO refactor provider to FC -> and move recoil to provider
  const receiptType = useRecoilValue(receiptTypeAtom);
  const currentUserSharedAccount = useRecoilValue(
    currentUserSharedAccountSelector,
  );
  const userId = useRecoilValue(userIdSelector);
  const { setActiveSliceId, rootID, activeSliceId } = useRootContext();
  const {
    sortFilter: { sortField, sortType },
    dateFromFilter,
    dateToFilter,
    isRoot,
    filterMadeBy,
    searchFilter,
  } = useRootContext();
  const [receiptToEdit, setReceiptToEdit] = useRecoilState(receiptToEditAtom);
  const [tempIdMap, setTempIdMap] = useRecoilState(tempIdMapAtom);
  const [selectedCategoryIdsList, setSelectedCategoryIdsList] = useRecoilState(
    selectedCategoryIDsAtom,
  );
  const [selectedReceiptIdsList, setSelectedReceiptIdsList] = useRecoilState(
    selectedReceiptIDsAtom,
  );

  useEffect(() => {
    setLogoutFunction(logout);
  }, [logout]);

  return (
    <CategoryTreeProvider
      {...{
        searchFilter,
        currentUserSharedAccount,
        receiptType,
        filterMadeBy,
        dateFrom: dateFromFilter,
        dateTo: dateToFilter,
        userId,
        activeSliceId,
        setActiveSliceId,
        isRoot,
        rootId: rootID,
        sortField,
        sortType,
        receiptToEdit,
        setReceiptToEdit,
        tempIdMap,
        setTempIdMap,
        selectedCategoryIdsList,
        setSelectedCategoryIdsList,
        selectedReceiptIdsList,
        setSelectedReceiptIdsList,
      }}
    >
      <RootWrapper>
        <Switch>
          <AuthRoute
            path='/auth'
            component={AuthPage}
            hasAccess={!isAuthorized}
            redirect={PAGE_ROUTES.category}
          />
          <AuthRoute
            path={PAGE_ROUTES.login}
            component={LoginPage}
            hasAccess={!isAuthorized}
            redirect={PAGE_ROUTES.category}
          />
          <AuthRoute
            path='/otp-login'
            component={OtpLogin}
            hasAccess={!isAuthorized}
            redirect={PAGE_ROUTES.category}
          />
          <AuthRoute
            path='/forgot-password'
            component={ForgotPasswordPage}
            hasAccess={!isAuthorized}
            redirect={PAGE_ROUTES.category}
          />
          <AuthRoute
            path='/reset-password'
            component={ResetPassword}
            hasAccess={!isAuthorized}
            redirect={PAGE_ROUTES.category}
          />
          <AuthRoute
            path='/sign-up'
            component={SignUp}
            hasAccess={!isAuthorized}
            redirect={PAGE_ROUTES.category}
          />
          <AuthRoute
            path={PAGE_ROUTES.mailConfirm}
            component={MailConfirmPage}
            hasAccess={!isAuthorized}
            redirect={PAGE_ROUTES.category}
          />
          <AuthRoute
            path={PAGE_ROUTES.category}
            component={Category}
            hasAccess={isAuthorized}
          />
          <AuthRoute
            path={PAGE_ROUTES.settings}
            component={Settings}
            hasAccess={isAuthorized}
          />
          <Redirect to={PAGE_ROUTES.category} />
        </Switch>
      </RootWrapper>
    </CategoryTreeProvider>
  );
});
