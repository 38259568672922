import {
  useAddAccountMutation,
  useRemoveAccountMutation,
} from '@/graphql/codegen/graphql';
import {
  currentUserPersonalAccountSelector,
  currentUserSharedAccountsSelector,
} from '@/store/user-store';
import { useRecoilValue } from 'recoil';
import { useGetUser } from '@/gql/api-user';

export type UpdateAccountPayload = {
  email: string;
  canViewGroup: boolean;
  canEditGroup: boolean;
  canDeleteGroup: boolean;
};

export const useQuerySharedAccounts = () => {
  const { fetchUser } = useGetUser();
  const [_mutationAddAccount, { loading: processingAddEmail }] =
    useAddAccountMutation();
  const [_mutationRemoveAccount, { loading: processingRemoveAccount }] =
    useRemoveAccountMutation();
  const personalAccount = useRecoilValue(currentUserPersonalAccountSelector);
  const sharedAccounts = useRecoilValue(currentUserSharedAccountsSelector);

  const addAccount = async (email: string) => {
    await _mutationAddAccount({
      variables: {
        email,
        canViewGroup: false,
        canEditGroup: false,
        canDeleteGroup: false,
      },
    });
    fetchUser();
  };

  const updateAccount = async (payload: UpdateAccountPayload) => {
    await _mutationAddAccount({
      variables: payload,
    });
    fetchUser();
  };

  const removeAccount = async (id: number) => {
    await _mutationRemoveAccount({
      variables: {
        sharing_id: id,
      },
    });
    fetchUser();
  };

  return {
    personalAccount,
    sharedAccounts,
    loading: processingAddEmail || processingRemoveAccount,
    addAccount,
    updateAccount,
    removeAccount,
  };
};
