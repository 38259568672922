import { useGetUser } from '@/gql/api-user';
import { useProfileEditMutation } from '@/graphql/codegen/graphql';
import { currentUserAtom, currentUserIconSelector } from '@/store/user-store';
import { useRecoilValue } from 'recoil';

export const useProfileEdit = () => {
  const [editProfile, { loading: loadProfile }] = useProfileEditMutation();

  const { fetchUser } = useGetUser();
  const user = useRecoilValue(currentUserAtom);
  const userIcon = useRecoilValue(currentUserIconSelector);

  const changeProfile = async ({
    currency,
    firstName,
    lastName,
    birthday,
    gender,
    customPicture,
  }: {
    currency: string;
    firstName: string;
    lastName: string;
    birthday?: number;
    gender: string;
    customPicture?: string;
  }) => {
    try {
      await editProfile({
        variables: {
          currency,
          firstName,
          lastName,
          birthday,
          gender,
          ...(customPicture ? { customPicture } : {}),
        },
      });
      fetchUser();
    } catch (error) {
      console.warn(error);
    }
  };

  return {
    loading: loadProfile,
    data: user,
    userIcon,
    changeProfile,
  };
};
