import type { HTMLAttributes } from 'react';

export type AppButtonProps = {
  onPress?(...args: any[]): void;
  className?: string;
  disabled?: boolean;
  type?: 'button' | 'submit';
} & HTMLAttributes<HTMLButtonElement>;

export const AppButton = ({
  children,
  className = '',
  disabled,
  onPress,
  type = 'button',
}: AppButtonProps) => {
  return (
    <button
      onClick={onPress}
      onTouchEnd={(e) => {
        if (onPress) {
          e.stopPropagation();
          e.preventDefault();
          onPress();
        }
      }}
      className={className}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
