import { useIntl } from 'react-intl';
import { SettingsIcon } from '../../settings-icon';
import { addClickHandle } from '@/services/helper-service';

export const ComingSoon = ({ onClick }: { onClick: () => void }) => {
  const { formatMessage: t } = useIntl();
  return (
    <>
      <SettingsIcon
        className='mt-[40px]'
        name='coming-soon'
        sizeType='custom'
      />

      <div className='ml-[21px] font-medium text-[22px] leading-[26px] text-center text-[var(--textMain)]'>
        {t({ id: 'menu.profile.plan.coming.soon' })}
      </div>

      <div
        {...addClickHandle(onClick)}
        className='cursor-pointer my-[61px] mx-auto flex justify-center items-center py-[5px] bg-[var(--emphasized)] rounded-[10px] w-full max-w-[343px]'
      >
        <SettingsIcon name='notify' sizeType='custom' />
        <span className='ml-[5px]  font-medium text-[14px] leading-[16px] text-white'>
          {t({ id: 'menu.profile.plan.notify' })}
        </span>
      </div>
      <div className='plan__coming-soon'>
        <span className='block my-0 mx-auto mt-[10px] text-center font-medium text-[14px] leading-[16px] text-[var(--textMain)]'>
          {t({ id: 'menu.profile.plan.soon' })}
        </span>
      </div>
    </>
  );
};
