import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { AppIcon } from '@/components';
import { addClickHandle } from '@/services/helper-service';
import c from 'classnames';

export const PermissionItem = ({
  onClick,
  permissionLabel = 'Label',
  permissionInfo = 'Permission info',
  isActive = false,
}: {
  onClick: () => void;
  permissionLabel?: string;
  permissionInfo?: string;
  isActive?: boolean;
}) => {
  const colorEmphasized = `var(${APP_CSS_THEME_VARIABLES.emphasized})`;

  return (
    <li className='permission-item'>
      <div
        {...addClickHandle(onClick)}
        className='mt-4 relative flex items-center'
      >
        <input
          className='absolute z-[-1] opacity-0'
          defaultChecked={isActive}
          type='checkbox'
        />

        {isActive && (
          <AppIcon
            className='absolute top-1 left-0.5 z-[50]'
            name='check'
            color={colorEmphasized}
          />
        )}

        <label
          className={c(
            'cursor-pointer w-full flex items-center mb-0 font-medium text-base leading-[22px] tracking-[-0.3px] capitalize text-[var(--textMain)]',
            {
              "before:content-[''] before:inline-block before:w-4 before:h-4 before:mr-[15px] before:flex-shrink-0 before:flex-grow-0 before:border before:border-[var(--emphasized)] before:rounded-[4px] before:bg-[var(--radioBackground)] before:shadow-[0_0_10px_var(--textMain20)] before:opacity-50":
                true,
              'before:opacity-100': isActive,
            },
          )}
        >
          {permissionLabel}
        </label>
      </div>

      <div className='ml-8 font-normal text-[9px] leading-[11px] tracking-[-0.3px] text-[var(--textMain)] opacity-30'>
        {permissionInfo}
      </div>
    </li>
  );
};
