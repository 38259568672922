import c from 'classnames';
import { CSSProperties, ReactNode, useRef, useState } from 'react';

interface TappableProps extends React.HTMLAttributes<HTMLDivElement> {
  onTap?: (event: React.SyntheticEvent) => void;
  onPress?: (event: React.SyntheticEvent) => void;
  className?: string;
  activeClassName?: string;
  style?: CSSProperties;
  activeStyle?: CSSProperties;
  children: ReactNode;
  component?: keyof JSX.IntrinsicElements;
  disabled?: boolean;
}

const componentMap = {
  div: (props) => <div {...props} />,
  button: (props) => <button {...props} />,
  li: (props) => <li {...props} />,
};

export const AppTappable = ({
  onTap,
  onPress,
  component = 'div',
  className = '',
  activeClassName = '',
  style = {},
  activeStyle = {},
  children,
  ...rest
}: TappableProps) => {
  const [isActive, setIsActive] = useState(false);
  const longPressTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const touchStartTimeRef = useRef<number>(0);
  const startXRef = useRef<number>(0);
  const startYRef = useRef<number>(0);
  const moveThreshold = 10; // Movement threshold in pixels to detect scrolling

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    touchStartTimeRef.current = Date.now();
    const touch = e.touches[0];
    startXRef.current = touch.clientX;
    startYRef.current = touch.clientY;
    setIsActive(true);
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    // const touchDuration = Date.now() - touchStartTimeRef.current;
    const touch = e.changedTouches[0];
    const moveX = Math.abs(touch.clientX - startXRef.current);
    const moveY = Math.abs(touch.clientY - startYRef.current);

    if (longPressTimeoutRef.current) {
      clearTimeout(longPressTimeoutRef.current);
      longPressTimeoutRef.current = null;
    }
    setIsActive(false);

    // Only trigger onTap if the touch movement was minimal (i.e., not a scroll)
    if (moveX < moveThreshold && moveY < moveThreshold) {
      onTap && onTap(e);
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    onTap && onTap(e);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onTap && onTap(e);
    }
  };

  const Component = componentMap[component];

  return (
    <Component
      className={c(`${className} ${isActive ? activeClassName : ''}`)}
      style={{ ...style, ...(isActive ? activeStyle : {}) }}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onKeyDown={handleKeyDown}
      role='button'
      tabIndex={0}
      {...rest}
    >
      {children}
    </Component>
  );
};
