import { AppIcon } from '@/components';
import { AppButton } from '@/components/app-button';
import { useRootContext } from '@/context/root-context';
import { useDetectMobileResolution } from '@/hooks';
import { madeByModalOpenedATOM } from '@/store/category-store';
import { useSetBooleanAtomState } from '@/store/category-store/category-store.callbacks';
import { useIntl } from 'react-intl';

const MOBILE_USER_ICON = { width: 22, height: 22 };
const DESKTOP_USER_ICON = { width: 30, height: 30 };

export const CategoryHeaderUsersContent = () => {
  const { formatMessage: t } = useIntl();
  const [openMadeByModal] = useSetBooleanAtomState(madeByModalOpenedATOM);
  const { filterMadeBy } = useRootContext();
  const isMobileResolution = useDetectMobileResolution();

  const userName =
    filterMadeBy.id === 'all' ? t({ id: 'all.profiles' }) : filterMadeBy.name;

  return (
    <AppButton
      className='p-[10px] pr-0 flex items-center cursor-pointer gap-[2px] text-[var(--actionText)] text-[16px] whitespace-nowrap overflow-hidden font-medium laptop:gap-[10px] hover:drop-shadow-[0_0_2px_var(--secondary)]'
      onPress={openMadeByModal}
    >
      <AppIcon
        name='users-icon'
        size={isMobileResolution ? MOBILE_USER_ICON : DESKTOP_USER_ICON}
      />
      <span className='overflow-hidden text-ellipsis block'>{userName}</span>
    </AppButton>
  );
};
