import {
  IonLabel,
  IonSelect,
  IonSelectOption,
  SelectChangeEventDetail,
} from '@ionic/react';
import c from 'classnames';
import { useIntl } from 'react-intl';
import { AppIonLabel } from '../app-ion-label';
import type { Option } from '@/components/app-select';

import './ion-select-overwrite.css';

type AppIonSelectProps = {
  className?: string;
  options: Option[];
  value: string[];
  onChange: (event: CustomEvent<SelectChangeEventDetail>) => void;
  isMultiple?: boolean;
  placeholder?: string;
  cancelText: string;
  confirmText: string;
  interfaceOptions?: any;
  label?: string;
  disabled?: boolean;
};

export const AppIonSelect = ({
  disabled = false,
  label,
  className = '',
  options,
  onChange,
  value,
  isMultiple = false,
  placeholder,
  interfaceOptions,
  cancelText,
  confirmText,
}: AppIonSelectProps) => {
  const { formatMessage: t } = useIntl();

  return (
    <div className={c('text-[16px]', className)}>
      {!!label && <AppIonLabel className='mb-[20px]' label={label} />}
      <IonSelect
        className='text-[var(--textMain)] border border-[var(--textMain60)] rounded-[8px] h-[18px] text-[16px] [&::part(icon)]:text-[var(--textMain)]'
        disabled={disabled}
        interfaceOptions={{
          ...interfaceOptions,
          cssClass: 'app-ion-select__alert',
        }}
        mode='ios'
        cancelText={cancelText}
        okText={confirmText}
        multiple={isMultiple}
        value={value}
        onIonChange={onChange}
        placeholder={placeholder ?? `${t({ id: 'select' })}...`}
      >
        {options.map(({ value, label }: Option) => (
          <IonSelectOption
            className='app-ion-select__option'
            key={value}
            value={value}
          >
            <IonLabel>{label}</IonLabel>
          </IonSelectOption>
        ))}
      </IonSelect>
    </div>
  );
};
