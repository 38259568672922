import { CategoryTabs } from '@/category/category-tabs';
import { AppHasAccess } from '@/components/app-has-access';
import { AppType } from '@/core.types';
import { categoryListViewAtom } from '@/store';
import {
  dragEnabledATOM,
  selectedCategoryIDsAtom,
  selectedReceiptIDsAtom,
} from '@/store/category-store';
import { useSelectedIDsAtomSetters } from '@/store/category-store/category-store.callbacks';
import { useContext, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { CategoryDropzones } from './category-dropzones';
import { CategoryGrid } from './category-grid';
import { CategoryListNoChild } from './category-no-child';
import { CategorySortHeader } from './category-sort-header';
import { CategoryItemTable } from './category-table';
import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import { useRootContext } from '@/context/root-context';

const viewMap = {
  tile: CategoryGrid,
  list: CategoryItemTable,
};

export const CategoryContent = () => {
  const { isRoot } = useRootContext();
  const categoryListView = useRecoilValue(categoryListViewAtom);
  const [_0, _1, clearSelectedCategories] = useSelectedIDsAtomSetters(
    selectedCategoryIDsAtom,
  );
  const [_2, _3, clearSelectedReceipts] = useSelectedIDsAtomSetters(
    selectedReceiptIDsAtom,
  );
  const dragEnabled = useRecoilValue(dragEnabledATOM);
  const View = viewMap[categoryListView];

  const {
    isEmptyActiveSliceSelector,
    activeSliceCategoryListSelectorFamily,
    activeSliceReceiptListSelectorFamily,
  } = useContext(CategoryTreeContext);

  const isSliceEmpty = isEmptyActiveSliceSelector();

  const foldersList = activeSliceCategoryListSelectorFamily();
  const emptyReceiptList = activeSliceReceiptListSelectorFamily('empty');
  const nonEmptyReceiptList = activeSliceReceiptListSelectorFamily('full');

  useEffect(() => {
    clearSelectedCategories();
    clearSelectedReceipts();
  }, [clearSelectedCategories, clearSelectedReceipts]);

  return (
    <div className='min-w-[300px] max-w-[1210px] w-full min-h-full m-auto transition-[0.2s] relative rounded-[15px] px-[15px] box-border bg-[var(--mainBackground)]'>
      <AppHasAccess appType={AppType.ireceipt}>
        <CategoryTabs />
      </AppHasAccess>
      <CategorySortHeader />
      {isSliceEmpty && <CategoryListNoChild />}
      {!isRoot && dragEnabled && <CategoryDropzones />}
      {!isSliceEmpty && (
        <View {...{ foldersList, emptyReceiptList, nonEmptyReceiptList }} />
      )}
    </div>
  );
};
