import { AppModalLayout } from '@/components/app-modal-layout';
import { CropStep } from '@/crop-step';
import { usePrepareLink } from '@/hooks/use-prepare-link';
import { FC, useEffect } from 'react';
import { Route } from 'react-router';
import { CreateReceipt } from '../create-receipt';
import { PreviewReceipt } from '../preview-receipt';

import { PAGE_ROUTES } from '@/app.constants';
import { CategoryPullToRefresh } from './category-pull-to-refresh';

export const Category = () => {
  const previewReceiptModalLink = usePrepareLink({
    to: PAGE_ROUTES.previewReceipt,
    isRelativePath: true,
  } as any);

  const updateReceiptLink = usePrepareLink({
    to: PAGE_ROUTES.updateReceipt,
    isRelativePath: true,
  } as any);

  const createReceiptLink = usePrepareLink({
    to: PAGE_ROUTES.createReceipt,
    isRelativePath: true,
  } as any);

  const cropStepLink = usePrepareLink({
    to: PAGE_ROUTES.cropStep,
    isRelativePath: true,
  } as any);

  const renderModalPageLayout =
    (Component: FC) =>
    ({ match }: any) => {
      return (
        <AppModalLayout open={Boolean(match)}>
          <Component />
        </AppModalLayout>
      );
    };

  useEffect(() => {
    window.newPoly.reset();
  }, []);

  return (
    <>
      <CategoryPullToRefresh />
      <Route
        path={previewReceiptModalLink.pathname}
        children={renderModalPageLayout(PreviewReceipt)}
      />
      <Route
        path={updateReceiptLink.pathname}
        children={renderModalPageLayout(CreateReceipt)}
      />
      <Route
        path={createReceiptLink.pathname}
        children={renderModalPageLayout(CreateReceipt)}
      />

      <Route
        path={cropStepLink.pathname}
        children={renderModalPageLayout(CropStep)}
      />
    </>
  );
};
