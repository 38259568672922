import { APP_COLOR_THEME_TYPE } from '@/color-theme.constants';
import { AppType } from '@/core.types';
import { hasAppAccess } from '@/services/app-service';
import { resolvePathSvg } from '@/services/helper-service';
import { appThemeTypeAtom } from '@/store/category-store';
import c from 'classnames';
import noop from 'lodash/noop';
import { useRecoilValue } from 'recoil';
import appLogo from '../images/auth/app-logo.png';
import appIdocumentLogo from '../images/auth/idocument-logo.png';

type AppLogoProps = {
  className?: string;
  onClick?(): void;
};

export const AppLogo = ({ className = '', onClick = noop }: AppLogoProps) => {
  const finalLogo = hasAppAccess(AppType.ireceipt) ? appLogo : appIdocumentLogo;

  const appColorType = useRecoilValue(appThemeTypeAtom);

  return (
    <img
      src={resolvePathSvg(finalLogo)}
      alt='logo'
      className={c(className, {
        'bg-[var(--secondary)] rounded-[30px] p-[15px]':
          appColorType === APP_COLOR_THEME_TYPE.LIGHT,
      })}
      onClick={onClick}
    />
  );
};
