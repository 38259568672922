import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import { AppIcon } from '@/components';
import { CategoryView, LocalReceipt, Maybe, ReceiptId } from '@/core.types';
import { useGetReceiptName } from '@/hooks/use-get-receipt-name';
import { resolveFilePath } from '@/services/helper-service';
import { categoryListViewAtom } from '@/store';
import c from 'classnames';
import { useContext } from 'react';
import { useRecoilValue } from 'recoil';

type DndPreviewItemProps = {
  id: ReceiptId;
};

const tableViewIconSize = {
  width: 29,
  height: 35,
};

export const DndPreviewReceiptItem = ({ id }: DndPreviewItemProps) => {
  const { getReceiptById } = useContext(CategoryTreeContext);
  const selectedReceipt: Maybe<LocalReceipt> = getReceiptById(id);
  const receiptName = useGetReceiptName({
    name: selectedReceipt?.name,
    type: selectedReceipt?.type,
  });
  const categoryListView = useRecoilValue(categoryListViewAtom);
  const isTableView = categoryListView === CategoryView.list;

  if (!selectedReceipt) {
    return null;
  }

  return (
    <div
      className={c('w-[130px] flex flex-col mb-[5px]', {
        'flex-row items-center': isTableView,
      })}
    >
      {isTableView ? (
        <AppIcon name='receipt' size={tableViewIconSize} />
      ) : (
        <img
          src={resolveFilePath(selectedReceipt.thumbPath)}
          className='w-[130px] h-[95px] object-cover object-center'
          alt=''
        />
      )}
      <div
        className={c(
          'text-[rgba(26,28,41,0.6)] font-medium text-[14px] leading-[16px] pb-[2px]',
          {
            'pt-0 ml-[5px]': isTableView,
          },
        )}
      >
        {receiptName}
      </div>
    </div>
  );
};
