import { useRootContext } from '@/context/root-context';
import { clearTokens } from '@/services/auth-service';
import {
  fileDownloadQueueTable,
  requestTable,
  waitUntilTableWillBeEmpty,
} from '@/services/indexed-db-service';
import { isAuthorizedAtom } from '@/store/root-store';
import { currentUserAtom } from '@/store/user-store';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { useRecoilCallback } from 'recoil';

export const useLogout = () => {
  const { setRootID, setActiveSliceId } = useRootContext();

  const logOut = useRecoilCallback(({ set }) => async () => {
    await waitUntilTableWillBeEmpty(requestTable);
    clearTokens();

    await Promise.all([
      fileDownloadQueueTable.clear(),
      GoogleAuth.signOut().catch(() => Promise.resolve()),
      set(isAuthorizedAtom, false),
      setRootID(null),
      set(currentUserAtom, null),
      setActiveSliceId(null),
    ]);
  });

  return logOut;
};
