import { useGetUser } from '@/gql/api-user';
import {
  useAddEmailMutation,
  useDeleteEmailMutation,
} from '@/graphql/codegen/graphql';
import { currentUserAtom } from '@/store/user-store';
import { useRecoilValue } from 'recoil';

export const useChangeEmail = () => {
  const { fetchUser } = useGetUser();
  const user = useRecoilValue(currentUserAtom);

  const [_mutationAddEmail, { loading: processingAddEmail }] =
    useAddEmailMutation();
  const [_mutationDeleteEmail, { loading: processingDeleteEmail }] =
    useDeleteEmailMutation();

  const addEmail = async (email: string) => {
    try {
      await _mutationAddEmail({
        variables: { email },
      });
      fetchUser();
    } catch (error) {
      console.warn(error);
    }
  };

  const deleteEmail = async (id: number) => {
    try {
      await _mutationDeleteEmail({
        variables: { id },
      });
      fetchUser();
    } catch (error) {
      console.warn(error);
    }
  };

  return {
    loading: processingDeleteEmail || processingAddEmail,
    dataEmails: user,
    deleteEmail,
    addEmail,
  };
};
