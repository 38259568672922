import { useResetRecoilLastUpdatedBasedOnSharedAccounts } from '@/gql/api-user';
import {
  CreateOrUpdateCategoryMutationVariables,
  useCreateOrUpdateCategoryMutation,
  useDeleteCategoriesMutation,
} from '@/graphql/codegen/graphql';
import { useErrorMiddleware } from '@/hooks/use-server-logic';
import { categoryLoadingAtom } from '@/store/category-store';
import { getSnapshotValue } from '@/store/root-store';
import { getUserSharedAccount } from '@/store/user-store';
import { useRecoilCallback } from 'recoil';
import type {
  LocalCategory,
  LocalReceipt,
  Maybe,
  ServerErrorResponse,
} from '@/core.types';
import { getCategoryList } from './get-category-list';

const EMPTY_LISTS = {
  receipts: [] as LocalReceipt[],
  categories: [] as LocalCategory[],
  accountId: null,
};

type FetchCategoryTreeDTO = {
  lastCategoryUpdatedAt: Maybe<number>;
  lastReceiptUpdatedAt: Maybe<number>;
};

export const useGetCategory = () => {
  const errorMiddleware = useErrorMiddleware();
  const resetRecoilLastUpdatedBasedOnSharedAccounts =
    useResetRecoilLastUpdatedBasedOnSharedAccounts();

  const fetchCategoryTree = useRecoilCallback(
    ({ set, snapshot }) =>
      async (lastUpdatedDTO: FetchCategoryTreeDTO) => {
        try {
          const categoryLoading = getSnapshotValue(
            snapshot,
            categoryLoadingAtom,
          );
          await window.onlineDispatcher.waitForReady();
          if (!window.onlineDispatcher.isOnline || categoryLoading) {
            return EMPTY_LISTS;
          }
          set(categoryLoadingAtom, true);
          let data: any = null;
          try {
            // @ts-ignore
            data = (await getCategoryList(lastUpdatedDTO))['data'];
          } catch (e) {
            console.log('Error on fetch Category List: ');
            console.log(e);
            return EMPTY_LISTS;
          } finally {
            set(categoryLoadingAtom, false);
          }

          const receipts: LocalReceipt[] = data?.user?.currentAccount?.receipts;
          const categories: LocalCategory[] =
            data?.user?.currentAccount?.categories;
          const accountId = data?.user?.currentAccount?.id;
          const newCurrentSharedAccount = getUserSharedAccount(data);
          resetRecoilLastUpdatedBasedOnSharedAccounts(newCurrentSharedAccount);

          return { receipts, categories, accountId };
        } catch (errorResponse) {
          set(categoryLoadingAtom, false);
          await errorMiddleware(
            (errorResponse as any)?.response as ServerErrorResponse,
          );
          return EMPTY_LISTS;
        }
      },
  );

  return {
    fetchCategoryTree,
  };
};

export const useCreateOrUpdateCategoryRequest = () => {
  const [createOrUpdateCategoryRequest] = useCreateOrUpdateCategoryMutation();

  return {
    createOrUpdateCategoryRequest,
  };
};

export const useCreateCategory = () => {
  const { createOrUpdateCategoryRequest } = useCreateOrUpdateCategoryRequest();

  return {
    createCategory: (variables: CreateOrUpdateCategoryMutationVariables) => {
      return createOrUpdateCategoryRequest({
        variables: variables,
      });
    },
  };
};

export const useUpdateCategory = () => {
  const { createOrUpdateCategoryRequest } = useCreateOrUpdateCategoryRequest();

  return {
    updateCategory: (variables: CreateOrUpdateCategoryMutationVariables) => {
      return createOrUpdateCategoryRequest({
        variables,
      });
    },
  };
};

export const useDeleteCategoryList = () => {
  const [deleteCategories] = useDeleteCategoriesMutation();

  return {
    deleteCategories,
  };
};
